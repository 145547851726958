/* eslint-disable react/button-has-type */

import React from 'react';
import { keyBy } from 'lodash';
import { cls } from '../helpers';
import Spinner from './Spinner';

import './FormButton.css';

const State = keyBy(['disabled', 'ready', 'waiting']);

const FormButton = (props) => {
  const {
    className: classes,
    color = 'primary',
    size = 'md',
    children,
    disabled = false,
    state = disabled ? State.disabled : State.ready,
    type = 'button',
    width,
    ...rest
  } = props;
  const className = cls('FormButton', {
    color, size, state, width, type,
  }, classes);
  return (
    <button
      {...rest}
      className={className}
      disabled={state !== State.ready}
      type={type}
    >
      {state === State.waiting && (
        <Spinner className="FormButton-spinner" />
      )}
      <div className="FormButton-content">
        {children}
      </div>
    </button>
  );
};

export default FormButton;
