import React, {
  createRef, useEffect, useMemo, useRef, useState,
} from "react"
import {
  cloneDeep, find, identity, keyBy, lowerCase,
} from 'lodash';
import axios from 'redaxios';
import { navigate } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import { blockEvent, form } from '../frontend-shared/helpers';
import { FormButton } from '../frontend-shared/components';

import { sendGtagConversionEvent } from '../helpers/gtag';

import Select from '../components/Elements/Select';
import TextField from '../components/Elements/TextField';
import Layout from "../components/layout"
import SEO from "../components/seo"

const Radio = ({
  field,
  label,
  onChange,
  options,
  value: checked,
  ...rest
}) => (
  <>
    <p className="text-sm text-blue font-normal m-0 pt-10 pb-2">
      {label}
    </p>
    <div className="shipping_volume grid lg:grid-cols-4 grid-cols-2 flex flex-row gap-3">
      {options.map(({ label, value }) => {
        const id = `${field}-${value}`;
        return (
          <div
            className="selecotr-item font1 bg-white text-lg font-normal text-blue flex shadow-xl rounded-full justify-center py-4"
            key={id}
          >
            <input
              {...rest}
              className="selector-item_radio"
              checked={checked === value}
              id={id}
              onChange={onChange}
              type="radio"
              value={value}
            />
            <label htmlFor={id} className="selector-item_label text-lg text-blue">
              {label}
            </label>
          </div>
        );
      })}
    </div>
  </>
);

const mapOptions = ({
  options,

  firstIsNone = false,
  labelMap = identity,
  valueMap = identity,
}) => options.map((input, index) => ({
  label: firstIsNone && !index ? input : labelMap(input),
  value: firstIsNone && !index ? undefined : valueMap(input),
}));

const toOptionValue = (input) => [
  [/\s/g, '&#x20;'], [/\+/g, '&#x2b;'],
].reduce((value, morph) => value.replaceAll(...morph), input);

const shipmentOptions = mapOptions({
  options: ['0-500', '500-3K', '3K-20K', '20K+'],
  valueMap: (input) => toOptionValue(input.toLowerCase()),
});

const fields = [{
  Component: TextField,
  field: 'name',
  label: 'Your full name',
  maxLength: 63,
  name: 'name',
  required: true,
  type: 'text',
}, {
  Component: TextField,
  field: 'organization',
  label: 'Organisation name',
  maxLength: 63,
  name: 'organization',
  required: true,
  type: 'text',
}, {
  Component: TextField,
  field: 'email',
  label: 'Organisation email',
  maxLength: 63,
  name: 'email',
  required: true,
  type: 'email',
}, {
  Component: TextField,
  field: 'phone',
  label: 'Phone number: +xx xxx xxx xxx',
  maxLength: 31,
  minLength: 8,
  name: 'phone',
  pattern: '[+]{1}[0-9 -]*',
  required: false,
  type: 'tel',
}, {
  Component: TextField,
  field: 'message',
  label: 'Add Message',
  maxLength: 255,
  name: 'message',
  required: true,
  rows: 3,
  type: 'text',
}, {
  Component: Select,
  field: 'interest',
  label: 'I am interested in',
  name: 'interest',
  ref: createRef(null),
  required: true,
  options: mapOptions({
    options: [
      'I am interested in...',
      'Carrier',
      'Customer',
      'Investor',
      'Partner',
    ],
    firstIsNone: true,
    labelMap: (type) => `Being a${/^[aeiou]/i.test(type) ? 'n' : ''} ${/carrier/i.test(type) ? 'listed ' : ''}${type.toLowerCase()}`,
    valueMap: toOptionValue,
  }),
}, {
  Component: Radio,
  field: 'shipments',
  label: 'Shipping volume per month',
  name: 'shipments-radio',
  options: shipmentOptions,
  show: ({ interest }) => [
    toOptionValue('Customer'),
    toOptionValue('Carrier'),
  ].includes(interest),
}, {
  Component: Select,
  className: 'hidden',
  field: 'shipments',
  // label: 'Shipping volume per month',
  name: 'shipments',
  options: shipmentOptions,
}];

const staticZohoMetaFields = (
  <>
    <input type="text" name="xnQsjsdp" value="5415f13a37fdfe95794bf3f265bfae469ae6c2d0068174bb5e27f5db5219a775"/>
    <input type="hidden" name="zc_gad" value=""/>
    <input type="text" name="returnURL" value="https&#x3a;&#x2f;&#x2f;carriyo.com&#x2f;post-submission" />
    <select name="Lead Source">
      <option selected value="Website - Contact Us">
        Contact Us
      </option>
    </select>
  </>
);

const ZohoEntity = keyBy(['LEAD', 'CONTACT'], lowerCase);

const submissionProps = {
  [ZohoEntity.lead]: {
    action: 'https://crm.zoho.com/crm/WebToLeadForm',
    name: 'WebToLeads5159490000000757011',
    fieldMap: {
      name: 'Last Name',
      organization: 'Company',
      email: 'Email',
      phone: 'Phone',
      message: 'LEADCF1',
      interest: 'LEADCF3',
      shipments: 'LEADCF2',
    },
    metaFields: (
      <>
        <input type="text" name="xmIwtLD" value="99b909cfe362a59d7be202acdef7be6ad5740c0f7872f7edcf7368448f4846b8"/>
        <input type="text" name="actionType" value="TGVhZHM="/>
      </>
    ),
  },
  [ZohoEntity.contact]: {
    action: 'https://mw.carriyo.com/public/contact-us',
    // use this hidden field to block bots if they fill it
    metaFields: <input type="text" name="age" value="" />,
  },
};

const defaultConfig = Object.fromEntries(fields.map(({
  Component, field,
}) => [
  field, Component === TextField ? '' : undefined,
]));

const ContactPage = (props) => {
  const formRef = useRef(null);

  const [actionState, setActionState] = useState('ready');
  const [error, setError] = useState(undefined);
  const [state, setState] = useState(cloneDeep(defaultConfig));

  useEffect(() => { setError(undefined); }, [state]);

  const submitted = actionState === 'disabled';

  const { title, subtitle } = submitted ? {
    title: 'We\'ll get back to you shortly',
    subtitle: 'Our customer service team is working hard. We\'re excited to connect and see how we can help!',
  } : {
    title: 'Contact us',
    subtitle: 'Get in touch and our team will contact you as soon as possible.',
  };

  const { interest } = state;

  const zohoEntity = interest === toOptionValue('Customer')
    ? ZohoEntity.lead
    : ZohoEntity.contact;

  const {
    fieldMap = {},
    metaFields: dynamicZohoMetaFields,
    ...formProps
  } = useMemo(() => submissionProps[zohoEntity], [zohoEntity]);

  const renderField = ({
    Component, field, name, ref, show = () => true, ...rest
  }) => {
    if (!show(state)) return null;
    const fieldName = fieldMap[name] || name;
    return (
      <Component
        {...rest}
        name={fieldName}
        key={`${field}-${fieldName}`}
        field={field}
        onChange={({ target }) => {
          setState({ ...state, [field]: target.value });
          ref?.current?.setCustomValidity('');
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') blockEvent(event);
        }}
        ref={ref}
        value={state[field]}
      />
    );
  };

  const preSubmit = (event) => {
    if (form.checkValidity(formRef, event)) {
      if (!interest) {
        const input = find(fields, { field: 'interest' }).ref.current;
        input.setCustomValidity('Please provide your interest');
      }
    }
    form.submit(formRef);
  };

  const onSubmit = (event) => {
    blockEvent(event);
    const form = formRef.current;
    const url = form.getAttribute('action');
    const method = form.getAttribute('method');
    setActionState('waiting');
    const formData = new FormData(form);
    return axios[method](
      url, /zoho/.test(url) ? formData : Object.fromEntries(formData),
    ).then(() => {
      sendGtagConversionEvent('AW-604624620/gpj1CLC56N8ZEOytp6AC');
      navigate('/contact/success'); // setActionState('disabled');
      const fieldEntries = fields.map(
        ({ field }) => [field, state[field]],
      ).filter(([key, val]) => val);
      fieldEntries.forEach(([field, value]) => {
        localStorage.setItem(field, value);
      });
      setState(cloneDeep(defaultConfig));
    }).catch(({ data: { err } }) => {
      setActionState('ready');
      setError(err || 'Error submitting form');
    });
  };

  return(
    <Layout>
      <SEO
        context={{
          ...props.pageContext,
          meta_title: 'Carriyo | Contact Us',
        }}
        cpath={props.location.pathname}
      />
      <div className="background1">
        <div className="w-full md:py-40 py-20">
          <div className="max-w-7xl mx-auto px-4">
            <div className="flex lg:gap-56 gap-12 md:flex-row flex-col flex-col-reverse">
              <div className="md:w-1/2 w-full pl-2">
                <h1 className="md:block hidden lg:text-7xl text-5xl font-medium text-blue mb-7">
                  {title}
                </h1>
                <p className="md:block hidden text-blue lg:text-xl text-lg font-normal mb-10">
                  {subtitle}
                </p>
                {!submitted && (
                  <>
                    <StaticImage className="rounded-2xl mb-10" src="../assets/images/mapimg.png" alt="" width="550" height="340" />
                    <div className="mb-2 text-base font-normal text-blue">
                      Locations
                    </div>
                    <ul className="List">
                      {[
                        'Dubai, United Arab Emirates',
                        'London, United Kingdom',
                      ].map((address) => (
                        <li className="lg:text-xl text-lg">
                          {address}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
              <form
                {...formProps}
                acceptCharset="utf-8"
                className="formblock md:w-1/2 w-full m-0"
                method="post"
                onSubmit={onSubmit}
                ref={formRef}
              >
                <button className="hidden" hidden type="submit">submit</button>
                <h2 className="md:hidden block  sm:text-5xl text-4xl font-medium text-blue mb-7">
                  {title}
                </h2>
                <p className="md:hidden block text-blue lg:text-xl text-md font-normal mb-10">
                  {subtitle}
                </p>
                {!submitted && (
                  <div className="md:border-0 md:bg-inherit bg-white border md:p-0 py-6 px-4 rounded-2xl md:shadow-none shadow-xl">
                    <p className="text-blue lg:text-xl text-lg font-normal pb-6">
                      We’d like to get to know you a bit more. Fill in the form and one of our sales team will get back to you shortly.
                    </p>
                    <div className="hidden">
                      {staticZohoMetaFields}
                      {dynamicZohoMetaFields}
                    </div>
                    {fields.map(renderField)}
                    <div className="h-12" />
                    <FormButton
                      onClick={preSubmit}
                      size="lg"
                      state={actionState}
                      width="full"
                    >
                      {error || 'Submit'}
                    </FormButton>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
